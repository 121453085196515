import clsx from "clsx";
import { FC, useRef } from "react";
import { useToggle, useOnClickOutside } from "usehooks-ts";

import { useTheme } from "context/ThemeContext";
import { USAIcon, ChevronIcon } from "icons";
import { IconType } from "icons/types";
import { LANGUAGE, LanguageType } from "locales";

const LANGUAGES: Record<LanguageType, { value: LanguageType; icon: IconType }> = {
  [LANGUAGE.En]: { value: LANGUAGE.En, icon: USAIcon },
  // [LANGUAGE.Es]: { value: LANGUAGE.Es, icon: SpainIcon },
  // [LANGUAGE.Pt]: { value: LANGUAGE.Pt, icon: BrazilIcon },
};

interface ILanguageSwitcherProps {
  className?: string;
  popoverClassName?: string;
}

export const LanguageSwitcher: FC<ILanguageSwitcherProps> = ({ className, popoverClassName }) => {
  const [isOpen, toggleIsOpen, setIsOpen] = useToggle(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const { locale, setLocale } = useTheme();

  const handleChangeLocale = (locale: LanguageType) => {
    setIsOpen(false);
    setLocale(locale);
  };

  const CurrentFlag = LANGUAGES[locale].icon;

  return (
    <div className={clsx("relative flex items-center", className)} ref={ref}>
      <button className="relative flex items-center" onClick={toggleIsOpen}>
        <p className="mr-1 text-caption uppercase text-grey-100">{LANGUAGES[locale].value}:</p>
        <CurrentFlag className="h-[26px] w-[26px]" />
        <div className="absolute -bottom-1 right-0 flex h-3 w-3 items-center justify-center rounded-md bg-grey-700">
          <ChevronIcon
            className={clsx("h-1 w-1 text-blue-100 duration-300", {
              "rotate-180": isOpen,
            })}
          />
        </div>
      </button>
      <div
        className={clsx(
          "absolute right-0 top-10 z-50 items-center rounded border border-white bg-white p-4 shadow-lg",
          {
            flex: isOpen,
            hidden: !isOpen,
          },
          popoverClassName
        )}
      >
        {Object.values(LANGUAGES).map(({ value, icon: Icon }) => (
          <button
            key={value}
            className={clsx("mr-2 rounded-full border-2 last:m-0", {
              "border-blue-700": value === LANGUAGES[locale].value,
              "border-transparent hover:border-blue-700": value !== LANGUAGES[locale].value,
            })}
            onClick={() => handleChangeLocale(value)}
          >
            <Icon className="h-7 w-7" />
          </button>
        ))}
      </div>
    </div>
  );
};
