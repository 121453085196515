import { FormattedMessage } from "react-intl";

// import EnviagoYoutubeVideoButton from "components/EnviagoYoutubeVideoButton";
import { Button } from "components/ui";
// import { useTheme } from "context/ThemeContext";
// import { LANGUAGE } from "locales";

const Block1 = () => {
  // const { locale } = useTheme();

  return (
    <div className="container justify-between pt-6 lg:flex lg:flex-row-reverse lg:pb-6 lg:pt-10">
      <div className="flex flex-col items-center text-center lg:ml-28 lg:w-1/2 lg:items-start lg:text-left">
        <h1 className="h2 mb-4 lg:mt-22 lg:text-[64px]">
          <FormattedMessage id="landing.block1.title" />
        </h1>
        <p className="h6 mb-8 text-grey-400 lg:pr-12">
          <FormattedMessage id="landing.block1.description" />
        </p>
        <div className="mb-8 flex items-center lg:mb-0">
          {/* {(locale === LANGUAGE.Es || locale === LANGUAGE.Pt) && (
            <>
              <EnviagoYoutubeVideoButton variant="text" className="mr-2 !w-40 underline" />
            </>
          )} */}
          <Button className="!w-40" component="Link" to="/welcome">
            <FormattedMessage id="landing.block1.button" />
          </Button>
        </div>
      </div>
      <img
        className="mx-auto -mb-4 w-full max-w-[560px] lg:-ml-8 lg:mb-0 lg:mr-auto"
        src="images/landing-block1.png"
        alt="Get paid"
      />
    </div>
  );
};

export default Block1;
