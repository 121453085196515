//eslint-disable-next-line
import enLocaleDateFns from "date-fns/locale/en-US";
//eslint-disable-next-line
// import esLocaleDateFns from "date-fns/locale/es";
//eslint-disable-next-line
// import ptLocaleDateFns from "date-fns/locale/pt";

import enLocale from "./en.json";
// import esLocale from "./es.json";
// import ptLocale from "./pt.json";

export const LANGUAGE = {
  En: "en",
  // Es: "es",
  // Pt: "pt",
} as const;

export const DATE_FNS_LOCALE = {
  en: enLocaleDateFns,
  // es: esLocaleDateFns,
  // pt: ptLocaleDateFns,
} as const;

export type LanguageType = (typeof LANGUAGE)[keyof typeof LANGUAGE];

export const AppLocale: Record<
  LanguageType,
  { locale: LanguageType; messages: Record<string, string> }
> = {
  [LANGUAGE.En]: {
    locale: LANGUAGE.En,
    messages: {
      ...enLocale,
    },
  },
  // [LANGUAGE.Es]: {
  //   locale: LANGUAGE.Es,
  //   messages: {
  //     ...esLocale,
  //   },
  // },
  // [LANGUAGE.Pt]: {
  //   locale: LANGUAGE.Pt,
  //   messages: {
  //     ...ptLocale,
  //   },
  // },
};
